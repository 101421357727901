function ScrollArrow({ position }: { position: number }) {
  const show = position < 250;

  return (
    <div className="w-screen -mt-16 flex justify-center items-center">
      <svg
        fill="none"
        viewBox="0 0 24 24"
        className={`h-16 w-16 transition animate-bounce ease-out duration-[250ms] delay-75 stroke-white ${
          show ? "opacity-100 -translate-y-20" : "opacity-0"
        }`}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 6v12m0 0-5-5m5 5 5-5"
        />
      </svg>
    </div>
  );
}

export default ScrollArrow;
