import { TestimonialData } from "../types/json_structure";
import { Carousel } from "flowbite-react";

function Testimonial({ testimonials }: { testimonials: TestimonialData[] }) {
  return (
    <div className="h-screen flex py-24 px-48 items-center place-content-center">
      {testimonials.length > 1 ? (
        <Carousel className="h-1/2 w-3/4">
          {testimonials.map((t) => RenderTestimonial(t))}
        </Carousel>
      ) : (
        <div className="h-1/2 w-3/4">{RenderTestimonial(testimonials[0])}</div>
      )}
    </div>
  );
}

function RenderTestimonial(t: TestimonialData) {
  return (
    <div className="flex flex-col h-full py-8 px-24 items-center" key={t.name}>
      <a href={t.url} target="_blank" rel="noreferrer noopener">
        <img
          src={process.env.REACT_APP_API_URL + "images/" + t.image_url}
          alt={t.name}
          className="object-fill rounded-full border-solid border-2 border-sky-300 size-14 lg:size-28"
        />
      </a>
      <h3 className="text-xl mt-4 text-gray-100 text-center">{t.name}</h3>
      <div className="no-scrollbar overflow-y-scroll text-gray-300 mt-2 text-justify">
        "{t.description}"
      </div>
    </div>
  );
}

export default Testimonial;
