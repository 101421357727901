import github from "./github.svg";
import linkedin from "./linkedin.svg";
import itchio from "./itchio.svg";
import gmail from "./gmail.svg";

const images = {
  github: github,
  linkedin: linkedin,
  itchio: itchio,
  gmail: gmail,
};

export default images;
