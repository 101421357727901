import { IntroData } from "../types/json_structure";
import images from "../images/images";

interface IntroProps {
  intro: IntroData;
}

function Intro({ intro }: IntroProps) {
  return (
    <article className="min-h-screen flex items-center justify-center align-middle">
      <div className="flex-col w-6/12">
        <h1 className="text-3xl text-gray-100">{intro.name}</h1>
        <h2 className="text-xl text-gray-400">{intro.position}</h2>
        <p className="text-gray-300 pt-12">{intro.description}</p>
        <div className="flex flex-row pt-4 space-x-4">
          <a
            href="https://www.linkedin.com/in/tohliyuan/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={images.linkedin} alt="LinkedIn" className="h-8 w-8" />
          </a>
          <a
            href="https://www.github.com/GamerYuan"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={images.github} alt="GitHub" className="h-8 w-8" />
          </a>
          <a
            href="https://gameryuan.itch.io/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={images.itchio} alt="itch.io" className="h-8 w-8" />
          </a>
          <a
            href="mailto:tohliyuan@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={images.gmail} alt="gmail" className="h-8 w-8" />
          </a>
        </div>
      </div>
    </article>
  );
}

export default Intro;
