import { ProjectData } from "../types/json_structure";
import { useState } from "react";

function Project({
  projects,
  position,
}: {
  projects: ProjectData[];
  position: number;
}) {
  const [transition, setTransition] = useState(false);
  const trigger = transition ? position > 250 : position > 650;

  const handleTransition = () => {
    if (transition) setTransition(false);
    return "";
  };

  return (
    <div className="min-h-screen py-24">
      <div className="flex overflow-x-auto overflow-y-clip w-screen no-scrollbar pt-8 px-16 space-x-5">
        {projects.map((project) => (
          <div
            onTransitionEnd={() => setTransition(true)}
            className={`flex-col flex-none w-96 p-8 shadow-lg rounded-xl bg-gray-900 
              transition-translate duration-300 left-screen relative ease-out
              ${
                transition
                  ? "hover:-translate-y-8"
                  : trigger
                  ? getDelay(projects.indexOf(project))
                  : ""
              }
              ${trigger ? `-translate-x-screen` : handleTransition()}`}
            key={project.title}
          >
            <img
              src={
                process.env.REACT_APP_API_URL + "images/" + project.image_url
              }
              className="object-contain pt-4 h-64 w-96 place-self-stretch"
              alt={project.title}
            />
            <div className="flex flex-col h-48 pt-2">
              <h3 className="text-xl text-gray-100 text-center">
                {project.title}
              </h3>
              <div className="no-scrollbar overflow-y-scroll text-gray-300 mt-2 text-justify">
                {project.description}
              </div>
            </div>
            <a
              href={project.link}
              target="_blank"
              rel="noreferrer noopener"
              className="text-blue-400 flex pt-2 align-middle w-[6.5rem]"
            >
              Learn More
              <svg
                fill="none"
                className="stroke-blue-400 stroke-2 fill-none h-4 w-4 rounded-lg"
                viewBox="0 0 24 24"
              >
                <path d="M10 5H8.2c-1.12 0-1.68 0-2.108.218a1.999 1.999 0 0 0-.874.874C5 6.52 5 7.08 5 8.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874c.427.218.987.218 2.105.218h7.606c1.118 0 1.677 0 2.104-.218.377-.192.683-.498.875-.874.218-.428.218-.987.218-2.105V14m1-5V4m0 0h-5m5 0-7 7" />
              </svg>
            </a>
            <div className="flex flex-wrap mt-4 mb-4 h-24 overflow-y-scroll no-scrollbar content-start">
              {project.tech.map((tech) => (
                <div
                  key={tech}
                  className="bg-blue-600 text-gray-100 h-8 text-s px-2 py-1 rounded-2xl mr-2 mb-2 min-w-fit"
                >
                  {tech}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function getDelay(index: number) {
  return {
    0: "delay-[150ms]",
    1: "delay-[300ms]",
    2: "delay-[425ms]",
    3: "delay-[525ms]",
    4: "delay-[600ms]",
    5: "delay-[650ms]",
  }[Math.min(index, 5)];
}

export default Project;
