import "./style.css";
import Intro from "./pages/intro";
import Project from "./pages/project";
import { useRef, useState, useEffect } from "react";
import ScrollArrow from "./components/scroll_arrow";
import Testimonial from "./pages/testimonial";
import { JsonStructure } from "./types/json_structure";

function App() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [data, setData] = useState<JsonStructure>();
  const scrollDemoRef = useRef(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "getAll", { mode: "cors" })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setData(data.data);
      });
  }, []);

  const handleScroll = () => {
    if (scrollDemoRef.current) {
      const { scrollTop } = scrollDemoRef.current;
      setScrollPosition(scrollTop);
    }
  };

  return (
    <div className="bg-gray-800">
      {data && (
        <div
          className="flex-col h-screen overflow-x-hidden overflow-y-scroll no-scrollbar"
          ref={scrollDemoRef}
          onScroll={handleScroll}
        >
          <Intro intro={data.intro} />
          <Project projects={data.projects} position={scrollPosition} />
          <Testimonial testimonials={data.testimonials} />
        </div>
      )}
      {data && <ScrollArrow position={scrollPosition} />}
    </div>
  );
}

export default App;
